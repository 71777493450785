import "./../styles/docsTemplate.scss"
import React, { useContext, useEffect } from "react"
import DocsLayout from "../components/docs-layout/docs-layout"
import { graphql } from "gatsby"
import { Helper } from "../utils/helper"
import * as _ from "lodash"
import { LS_OPEN_HISTORY } from "../utils/constants"
import {
  SearchContext,
  SearchContextProvider,
} from "../contexts/search-context"
import VesHighlighter from "../components/highlighter"
import SEO from "../components/seo"
import { useActiveHash } from "../hooks/use-active-hash"
import { SelectedMenuContextProvider } from "../contexts/selected-menu-context"
import { navigate } from "gatsby"

const DocsTemplate = ({ data, pageContext }) => {
  const { urlParts } = pageContext
  const {
    markdownRemark: {
      html,
      frontmatter: { title, redirect, linked },
      fields: { relativePath },
      headings,
    },
  } = data

  const depthClasses = ["first", "second", "third", "fourth", "fifth", "sixth"]

  useEffect(() => {
    if (redirect) {
      navigate(redirect, { replace: true })
    }

    let pastOpenedPages = []
    try {
      pastOpenedPages =
        JSON.parse(sessionStorage.getItem(LS_OPEN_HISTORY)) || []
    } catch (e) {}

    sessionStorage.setItem(
      LS_OPEN_HISTORY,
      JSON.stringify(_.uniq([...pastOpenedPages, relativePath]))
    )
  }, [relativePath])

  const headingSlugs = headings.map(heading =>
    Helper.generateSlug(heading.value)
  )

  const activeHash = useActiveHash(headingSlugs, {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  })

  const depthClassMap = headings
    .map(heading => heading.depth)
    .filter((value, index, original) => original.indexOf(value) === index) // Removing Duplicates
    .sort((first, second) => first - second) // Sorting Ascending
    .reduce((prev, curr, index) => {
      prev[curr] = depthClasses[index]
      return prev
    }, {})

  const searchContext = useContext(SearchContext)

  const _getContent = () => {
    if (searchContext.query === "") {
      return html
    }

    return html.replace(
      new RegExp(
        `(?![^<>]*>)(${searchContext.query.replace(
          /([.?*+^$[\]\\(){}-])/g,
          "\\$1"
        )})`,
        "ig"
      ),
      "<mark>$1</mark>"
    )
  }

  const handleTOCClick = (event, index) => {
    event.preventDefault()
    window.document.querySelector(`#${headingSlugs[index]}`).scrollIntoView({
      behavior: "smooth",
    })
  }

  return (
    <DocsLayout relativePath={relativePath} urlParts={urlParts}>
      <SEO
        title={`${title} | Volterra Docs`}
        isArticle={true}
        dontIndex={linked === false}
      />
      <div className="docs-main-container">
        <h1 className="page-title">{title}</h1>
        {!!headings.length && (
          <div className="toc">
            <h5 className="toc__header">On This Page:</h5>
            <ul className="toc__content">
              {headings.map((heading, index) => (
                <li
                  key={heading.value}
                  className={depthClassMap[heading.depth]}>
                  <a
                    className={
                      activeHash === headingSlugs[index] ? "active" : ""
                    }
                    onClick={event => handleTOCClick(event, index)}
                    aria-label={`Open ${heading.value}`}
                    href={`#${headingSlugs[index]}`}>
                    {searchContext.query === "" && heading.value}
                    {searchContext.query !== "" && (
                      <VesHighlighter
                        searchTerm={searchContext.query}
                        text={heading.value}
                      />
                    )}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div
          className="data"
          dangerouslySetInnerHTML={{ __html: _getContent() }}
        />
      </div>
    </DocsLayout>
  )
}

export const docsPageQuery = graphql`
  query($relativePath: String!) {
    markdownRemark(
      fields: { relativePath: { eq: $relativePath } }
      frontmatter: { type: { eq: "content" } }
    ) {
      html
      fields {
        relativePath
        category
      }
      frontmatter {
        title
        slug
        redirect
        linked
      }
      headings(depth: h6) {
        value
        depth
      }
    }
  }
`

const DocsTemplateContainer = props => (
  <SelectedMenuContextProvider>
    <SearchContextProvider>
      <DocsTemplate {...props} />
    </SearchContextProvider>
  </SelectedMenuContextProvider>
)

export default DocsTemplateContainer
